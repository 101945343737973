import React, { useState } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom'
import Container from '@material-ui/core/Container/Container'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'

import theme from './theme/theme'

import Header from './components/Header/Header'
import Offer from './containers/Offer/Offer'
import NoOfferFound from './containers/NoOfferFound/NoOfferFound'

import Store from './model/store.model'



function App() {

    const [store, setStore] = useState<Store | null>(null)

    return (
        <MuiThemeProvider theme={theme}>
            <Container style={{ minWidth: "1000px" }}>
                <CssBaseline />

                <Header
                    store={store}
                />
                <Router>
                    <Switch>
                        <Route path="/:offerId" render={({ match }) => {
                            return (
                                <Offer
                                    setStore={setStore}
                                    offerId={match.params.offerId}
                                />
                            )
                        }} />
                        <Route path="/">
                            <NoOfferFound
                                setStore={setStore}
                            />
                        </Route>
                    </Switch>
                </Router>
            </Container>
        </MuiThemeProvider>




    )
}

export default App
