import React from 'react'
import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core'

import { GetRadiatorsTotalResponse } from '../../../utils/getRadiatorsTotal'

const CELL_PADDING = '0.5rem'

type RadiatorListTotalProps = {
    total: GetRadiatorsTotalResponse
}


const RadiatorListTotal: React.FC<RadiatorListTotalProps> = ({ total }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell style={{padding: CELL_PADDING}}>
                    <Typography variant="caption" style={{ fontWeight: 600 }}>Итого</Typography>
                </TableCell>
                <TableCell align="center" ><Typography variant="caption"></Typography></TableCell>
                <TableCell align="right" ><Typography variant="caption"></Typography></TableCell>
                <TableCell align="right" style={{padding: CELL_PADDING}}>
                    <Typography variant="caption" style={{ fontWeight: 600 }}>
                        {total.totalPower.dt50.toLocaleString()} / {total.totalPower.dt60.toLocaleString()} / {total.totalPower.dt70.toLocaleString()}
                    </Typography>
                </TableCell>
                <TableCell align="right" ><Typography variant="caption"></Typography></TableCell>
                <TableCell align="right" ><Typography variant="caption"></Typography></TableCell>
                <TableCell align="right" style={{padding: CELL_PADDING}}>
                    <Typography variant="caption" style={{ fontWeight: 600 }}>
                        {total.totalPrice.toLocaleString()}
                    </Typography>
                </TableCell>
                <TableCell align="right" ><Typography variant="caption"></Typography></TableCell>
                <TableCell align="right" style={{padding: CELL_PADDING}}>
                    <Typography variant="caption" color="secondary" style={{ fontWeight: 600}}>
                        {total.totalDiscountedPrice.toLocaleString()}
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default RadiatorListTotal