import React from 'react'
import { Box, Divider, Typography } from '@material-ui/core'

import RadiatorList from '../RadiatorsList/RadiatorsList'
import VersionImages from '../VersionImages/VersionImages'
import PowerComment from '../../elements/PowerComment/PowerComment'
import RoomList from '../RoomList/RoomList'
import VersionTotal from '../VersionTotal/VersionTotal'
import shrinkRadiators from '../../utils/shrinkRadiators'
import getRadiatorsTotal from '../../utils/getRadiatorsTotal'
import GeneralHeader from '../../elements/GeneralHeader/GeneralHeader'


import { OfferVersion } from '../../model/offer.model'


type VersionProps = {
    version: OfferVersion
    versionsQnty: number
    roomsViewMode: boolean
}

const Version: React.FC<VersionProps> = ({ version, versionsQnty, roomsViewMode }) => {

    const shrinkedRadiators = shrinkRadiators(version)
    const allTotals = getRadiatorsTotal(shrinkedRadiators)
    const generalRadiators = roomsViewMode ? version.radiators : shrinkedRadiators


    return (
        <Box paddingTop="2rem" marginBottom="4rem">

            {versionsQnty > 1 &&
                <Box>
                    <Typography variant="caption" color="primary">
                        вариант:
                    </Typography>
                    <Typography variant="h3" color="secondary">
                        {version.name}
                    </Typography>
                </Box>
            }
            <Typography variant="body1" >
                {version.description}
            </Typography>
            <Box paddingBottom="1rem">
                <Divider />
            </Box>

            {/* Помещения */}
            {roomsViewMode &&
                <RoomList
                    rooms={version.rooms}
                />
            }

            {/* Общий список */}
            {generalRadiators &&
                <>
                    <GeneralHeader noRooms={!version.rooms || !roomsViewMode} />
                    <RadiatorList
                        radiators={generalRadiators}
                    />
                </>
            }

            <Box marginTop="3rem">
                <VersionTotal
                    total={allTotals.totalPrice}
                    totalDiscounted={allTotals.totalDiscountedPrice}
                />
            </Box>
            <Box paddingTop="2rem">
                <PowerComment />
            </Box>
            <VersionImages
                images={version.images}
            />


        </Box>
    )
}

export default Version