import { createStyles, TableCell, TableHead, TableRow, Theme, Typography, withStyles } from '@material-ui/core'
import React from 'react'

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#eeeeee',
      padding: '0.5rem'
    },
    body: {
      fontSize: 14
    }
  }),
)(TableCell)

const RadiatorListTableHeader: React.FC = () => {
    return (
        <TableHead>
            <TableRow>
                <StyledTableCell style={{ width: "200px" }}>
                    <Typography variant="caption">Модель</Typography>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "50px" }}><Typography variant="caption">Сек.</Typography></StyledTableCell>
                <StyledTableCell align="right" style={{ width: "150px" }}><Typography variant="caption">Д х В х Г, мм</Typography></StyledTableCell>
                <StyledTableCell align="right" style={{ width: "170px" }}><Typography variant="caption">ΔT=50/60/70°C, Вт</Typography></StyledTableCell>
                <StyledTableCell align="right" style={{ width: "50px" }}><Typography variant="caption">шт.</Typography></StyledTableCell>
                <StyledTableCell align="right" style={{ width: "100px" }}><Typography variant="caption">Цена, руб</Typography></StyledTableCell>
                <StyledTableCell align="right" style={{ width: "150px" }}><Typography variant="caption">Сумма, руб</Typography></StyledTableCell>
                <StyledTableCell align="right" style={{ width: "50px" }}><Typography variant="caption">Скидка</Typography></StyledTableCell>
                <StyledTableCell align="right" style={{ width: "150px" }}><Typography variant="caption">Со скидкой, руб</Typography></StyledTableCell>
            </TableRow>
        </TableHead>
    )
}

export default RadiatorListTableHeader