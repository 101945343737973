import { Box, Grid, Link, Typography } from '@material-ui/core'
import React from 'react'
import { Offer } from '../../model/offer.model'
import Store from '../../model/store.model'

type OfferInfoProps = {
    offer: Offer
    store: Store | ""
    manager: string
}

const OfferInfo: React.FC<OfferInfoProps> = ({ offer, store, manager }) => {
    // console.log(offer)

    return (
        <Grid container spacing={3}>
            <Grid item sm={6} >
                <Typography variant="h5" display="inline" >
                    {offer.name}
                </Typography>
                <Box paddingTop="1rem">
                    <Typography variant="body1"  >
                        {offer.description}
                    </Typography>
                </Box>
            </Grid>
            <Grid item sm={6} >
                <Box paddingTop="1rem">
                    <Typography variant="body1" align="right">
                        подготовил: &nbsp;{manager}
                    </Typography>
                    {store !== '' &&
                        Object.values(store.phones).map(phone => {
                            return (
                                <Typography variant="body1" align="right" key={phone}>
                                    <Link href={`tel:${phone}`} color="inherit">{phone}</Link>
                                </Typography>
                            )
                        })
                    }
                    {store !== '' && store.email &&
                        <Typography variant="body1" align="right">
                            <Link href={`email:${store.email}`} color="inherit">{store.email}</Link>
                        </Typography>
                    }


                </Box>


            </Grid>
        </Grid>

    )
}

export default OfferInfo