import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'

type VersionTotalProps = {
    total: number
    totalDiscounted: number
}

const VersionTotal: React.FC<VersionTotalProps> = ({ total, totalDiscounted }) => {
    return (
        <Box>
            <Typography variant="h6">
                Итого
            </Typography>
            <Grid container spacing={10}>
                <Grid item >
                    <Typography variant="caption">
                        Сумма
                </Typography>
                    <Typography variant="h6">
                        {total.toLocaleString()} руб.
                </Typography>
                </Grid>
                <Grid item > 
                    <Typography variant="caption" color="secondary">
                        Сумма со скидкой
                </Typography>
                    <Typography variant="h6" color="secondary">
                        {totalDiscounted.toLocaleString()} руб.
                </Typography>
                </Grid>
            </Grid>


        </Box>
    )
}

export default VersionTotal