import React from 'react'
import { Box } from '@material-ui/core'

type VersionImagesProps = {
    images: string
}

const VersionImages: React.FC<VersionImagesProps> = ({ images }) => {

    if (!images) return null
    const imagesArray = images.split('\n')

    return (
        <Box paddingTop="3rem">
            {imagesArray.map(imageUrl => {
                if (imageUrl.length > 10) {
                    return (
                        <Box padding="2rem" display="inline" key="imageUrl">
                            <img
                                src={imageUrl}
                                alt="Радиаторы отопления"
                                style={{ maxHeight: "300px", maxWidth: "400px" }}
                            />
                        </Box>
                    )
                }
                return null

            })}
        </Box>
    )
}

export default VersionImages