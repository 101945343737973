import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'

import OfferInfo from '../../components/OfferInfo/OfferInfo'
import OfferVersions from '../../components/OfferVersions/OfferVersions'
import ViewModeSwitcher from '../../components/ViewModeSwitcher/ViewModeSwitcher'
import WaitingSpinner from '../../elements/WaitingSpinner/WaitingSpinner'

import useOfferData from '../../hooks/useOfferData'
import Store from '../../model/store.model'
import NoOfferFound from '../NoOfferFound/NoOfferFound'

type OfferProps = {
    offerId: string
    setStore: Dispatch<SetStateAction<Store | null>>
}

const Offer: React.FC<OfferProps> = ({ setStore, offerId }) => {

    const [roomsViewMode, setRoomsViewMode] = useState<boolean>(true)

    const offerData = useOfferData(offerId)
    useEffect(() => {
        if (offerData.error === false && offerData.pending === false && offerData.store !== '') {
            setStore(offerData.store)
        }
    }, [offerData, setStore])



    if (offerData.pending === true) {
        return <WaitingSpinner />
    }
    if (offerData.error === true) {
        return (
            <NoOfferFound
                setStore={setStore}
            />
        )
    }

    const anyRooms = Object.values(offerData.offer.versions)
        .some(version => {
            if (version.rooms) return true
            return false
        })


    if (offerData.offer !== undefined) {
        return (
            <Box paddingY="2rem">
                <OfferInfo
                    offer={offerData.offer}
                    store={offerData.store}
                    manager={offerData.manager}
                />
                {anyRooms &&
                    <ViewModeSwitcher
                        roomsViewMode={roomsViewMode}
                        toggleViewMode={() => setRoomsViewMode(!roomsViewMode)}
                    />
                }
                <OfferVersions
                    versions={offerData.offer.versions}
                    roomsViewMode={roomsViewMode}
                />
            </Box>
        )
    }

    return (
        <NoOfferFound
            setStore={setStore}
        />
    )

}

export default Offer