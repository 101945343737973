import React from 'react'
import { Box, CircularProgress, Typography } from '@material-ui/core'


const WaitingSpinner: React.FC = () => {
    return (
        <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <CircularProgress />
            <Box paddingY="2rem">
                <Typography variant="body1" align="center">
                    загружаю...
            </Typography>
            </Box>

        </div>
    )
}
export default WaitingSpinner