import { Box, Typography } from "@material-ui/core"
import React from "react"


type GeneralHeaderProps = {
    noRooms: boolean
}

const GeneralHeader: React.FC<GeneralHeaderProps> = ({ noRooms }) => {

    return (noRooms ?
        <Box paddingTop="3rem">
            <Typography variant="h5">
                Радиаторы и комплектующие
            </Typography>
        </Box>
        :
        <Box paddingTop="3rem">
            <Typography variant="h6" color="primary">
                ** общее **
            </Typography>
        </Box>
    )

}

export default GeneralHeader