import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'


import Store from '../../model/store.model'

type NoOfferFoundProps = {
    setStore: Dispatch<SetStateAction<Store | null>>
}

const emptyStore: Store = {
    id: 'default',
    name: 'Подбор радиаторов',
    email: 'sales@mouselite.ru',
    phones: {0: '+7 (800) 707-58-11'},
    website: 'https://mouselite.ru',
    logoUrl: ''
} 

const NoOfferFound: React.FC<NoOfferFoundProps> = ({setStore}) => {

    useEffect(() => {
        setStore(emptyStore)
    },[setStore])

    return (
        <div style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Box paddingY="2rem">
                <Typography variant="h4" align="center">
                   По данному адресу не найдено ни одного расчета...
            </Typography>
            </Box>

        </div>
    )
}
export default NoOfferFound