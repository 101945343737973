import { Box, Typography } from '@material-ui/core'
import React from 'react'

const PowerComment: React.FC = () => {

    return (
        <>
            <Typography variant="caption" display="inline" color="secondary">
                *
            </Typography>
            <Typography variant="caption" display="inline">
                Мощности радиаторов,обозначенные значком (*) не являются официальными, предоставленными производителем, но рассчитаны приблизительно по формуле: каждые дополнительные dT=10C увеличивают мощность радиатора на 25%. Данные мощности приведены в связи с тем, что производителем не предоставлена соответствующая информаия для требуемых температрных режимов.
            </Typography>
            <Box marginTop="0.5rem">
                <Typography variant="caption" display="block">
                    Мощности радиаторов указаны для трех температурных режимов: dT = 50C, dT = 60C и dT = 70C.
            </Typography>
            </Box>
        </>

    )
}

export default PowerComment