import { useState, useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'

import Store from '../model/store.model'
import { Offer } from '../model/offer.model'

type OfferData = {
    error: false
    pending: false
    store: Store | ''
    manager: string
    offer: Offer
}
    | { error: true, pending: false }
    | { error: false, pending: true }


const GET_OFFER_URL = 'https://us-central1-mouselite-helpers.cloudfunctions.net/api/offer/get'

const useOfferData = (offerId: string): OfferData => {

    const [offerData, setOfferData] = useState<OfferData>({ pending: true, error: false })

    useEffect(() => {
        console.log('launch hook')
        const getOffer = async () => {
            await axios
                .get(GET_OFFER_URL,
                    {
                        params: {
                            offerId: offerId
                        }
                    })
                .then((res: AxiosResponse) => {
                    setOfferData({ ...res.data, pending: false })
                })
                .catch((error) => {
                    console.log('error: ', error)
                    setOfferData({ error: true, pending: false })
                })
        }
        getOffer()
    }, [offerId])


    return offerData

}

export default useOfferData