import { OfferRadiator } from "../model/offer.model"

type GetRadiatorsTotalResponse = {
    totalPrice: number
    totalDiscountedPrice: number
    totalPower: {
        dt50: number
        dt60: number
        dt70: number
    }
}
type GetRadiatorsTotal = (radiators: { [radiatorId: string]: OfferRadiator }) => GetRadiatorsTotalResponse


const INITIAL_TOTAL: GetRadiatorsTotalResponse = {
    totalPrice: 0,
    totalDiscountedPrice: 0,
    totalPower: {
        dt50: 0,
        dt60: 0,
        dt70: 0,
    }
}

const getRadiatorsTotal: GetRadiatorsTotal = (radiators) => {
    const result: GetRadiatorsTotalResponse = Object.values(radiators)
        .reduce((acc, radiator) => {
            const discount = radiator.discount ? radiator.discount : 0
            const _acc = {
                totalPrice: acc.totalPrice + radiator.priceRub * radiator.quantity,
                totalDiscountedPrice: acc.totalDiscountedPrice + Math.floor(radiator.priceRub * radiator.quantity * (100 - discount) / 100),
                totalPower: {
                    dt50: Math.floor(acc.totalPower.dt50 + Math.abs(radiator.dt50) * radiator.quantity),
                    dt60: Math.floor(acc.totalPower.dt60 + Math.abs(radiator.dt60) * radiator.quantity),
                    dt70: Math.floor(acc.totalPower.dt70 + Math.abs(radiator.dt70) * radiator.quantity)
                }     
            }
            return _acc
        },
            INITIAL_TOTAL)
    return result
}

export default getRadiatorsTotal
export type { GetRadiatorsTotalResponse }