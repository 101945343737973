/* eslint-disable array-callback-return */
import { OfferRadiator } from "../model/offer.model"

type RemoveDuplicatesRadiators = (radiators: { [radiatorId: string]: OfferRadiator }) => { [radiatorId: string]: OfferRadiator }

const removeDuplicateRadiators: RemoveDuplicatesRadiators = (radiators) => {
    let result: { [radiatorId: string]: OfferRadiator } = {}

    Object.entries(radiators).map(([radiatorId, radiator]) => {
        const resRads = { ...result }
        const duplicated = Object.entries(resRads).some(([resRadId, resRad]) => {
            if (radiator.title === resRad.title &&
                radiator.sections === resRad.sections &&
                radiator.length === resRad.length &&
                radiator.width === resRad.width &&
                radiator.height === resRad.height &&
                radiator.dt60 === resRad.dt60

            ) {
                const newQnty = result[resRadId].quantity + radiator.quantity
                const newDiscount = (result[resRadId].discount * result[resRadId].quantity + radiator.discount * radiator.quantity) / newQnty

                result[resRadId].discount = newDiscount
                result[resRadId].quantity = newQnty

                return true
            } else {
                return false
            }
        })
        if (!duplicated) result[radiatorId] = { ...radiator }
    })

    return result
}

export default removeDuplicateRadiators