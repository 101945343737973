import React from "react"

import { AppBar, Grid, Toolbar } from "@material-ui/core"
import Store from "../../model/store.model"

const MOUSELITE_LOGO_URL = 'https://mouselite.ru/img/logo_ml.png'


type HeaderProps = {
    store: Store | null
}

const Header: React.FC<HeaderProps> = ({ store }) => {

    const storeName = store === null ? '' : store.name
    const storeLogoUrl = store?.logoUrl ? store.logoUrl : MOUSELITE_LOGO_URL
    const storeUrl = store?.website ? store.website : "#"

    return (
        <AppBar
            position="static"
            color="transparent"
        >
            <Toolbar style={{ backgroundColor: "#ffffff" }}>
                <Grid container>
                    <Grid item sm={8} >

                        {storeName !== '' &&
                            <a href={storeUrl} target="blank">
                                    <img
                                        src={storeLogoUrl}
                                        alt={storeName}
                                        style={{ maxWidth: "150px", maxHeight: "50px" }}
                                    />
                            </a>
                        }

                    </Grid>
                    <Grid item sm={2}>
                    </Grid>
                </Grid>

            </Toolbar>
        </AppBar >
    )
}



export default Header