import React from 'react'
import { Box } from '@material-ui/core'

import Room from '../Room/Room'

import { OfferRoom } from '../../model/offer.model'


type RoomListProps = {
    rooms: { [roomId: string]: OfferRoom }
}

const RoomList: React.FC<RoomListProps> = ({ rooms }) => {

    if (rooms === undefined) return null

    return (
        <Box>
            {
                Object.entries(rooms)
                    .sort((a, b) => a[1].createdAt > b[1].createdAt ? 1 : -1)
                    .map(([roomId, room]) => {
                        return (
                            <Room
                                key={roomId}
                                roomId={roomId}
                                room={room}
                            />
                        )
                    })
            }
        </Box>
    )
}

export default RoomList