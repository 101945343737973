import React from 'react'
import { TableCell, TableRow, Typography } from '@material-ui/core'


import { OfferRadiator } from './../../../model/offer.model'

const CELL_PADDING = "0.5rem"

type RadiatorListRowProps = {
    offerRadiator: OfferRadiator
}

const RadiatorListRow: React.FC<RadiatorListRowProps> = ({
    offerRadiator,
}) => {


    const rad = offerRadiator
    const discount = offerRadiator.discount ? offerRadiator.discount : 0
    const totalCost = rad.priceRub * rad.quantity
    const totalCostDiscounted = Math.floor(totalCost * (1 - discount / 100))

    const getPowerString = (power: number): any => {
        return power === 0 ? <Typography variant="caption" display="inline">-</Typography> :
            power > 0 ? <Typography variant="caption" display="inline">{Math.floor(power).toLocaleString()}</Typography> :
                (<>
                    <Typography variant="caption" display="inline">{Math.floor(Math.abs(power)).toLocaleString()}</Typography>
                    <Typography variant="caption" display="inline" color="secondary"> *</Typography>
                </>)
    }

    if (rad.isAccessory) {
        return (
            <TableRow>
                <TableCell style={{ padding: CELL_PADDING }} colSpan={4}>
                    <Typography variant="caption" display="inline">
                        {rad.title}
                    </Typography>
                </TableCell>

                <TableCell align="right" style={{ padding: CELL_PADDING }}>
                    <Typography variant="caption">
                        {offerRadiator.quantity}
                    </Typography>
                </TableCell>
                <TableCell align="right" style={{ padding: CELL_PADDING }}><Typography variant="caption">{rad.priceRub.toLocaleString()}</Typography></TableCell>
                <TableCell align="right" style={{ padding: CELL_PADDING }}><Typography variant="caption">{totalCost.toLocaleString()}</Typography></TableCell>
                <TableCell align="right" style={{ padding: CELL_PADDING }}><Typography variant="caption">
                    {discount}%
                </Typography></TableCell>
                <TableCell align="right" style={{ padding: CELL_PADDING }}><Typography variant="caption">{totalCostDiscounted.toLocaleString()}</Typography></TableCell>
            </TableRow>
        )
    }

    return (
        <TableRow>
            <TableCell style={{ width: "350px", padding: CELL_PADDING }}>
                <Typography variant="caption" display="inline">
                    {rad.title}
                </Typography>
            </TableCell>
            <TableCell align="center" style={{ padding: CELL_PADDING }}>
                <Typography variant="caption">
                    {rad.sections !== 0 ? rad.sections : '-'}
                </Typography>
            </TableCell>
            <TableCell align="right" style={{ padding: CELL_PADDING }}>
                <Typography variant="caption">
                    {rad.length !== 0 ? rad.length : '  -  '} x {rad.height !== 0 ? rad.height : '  -  '} x {rad.width !== 0 ? rad.width : '  -  '}
                </Typography>
            </TableCell>
            <TableCell align="right" style={{ padding: CELL_PADDING }}>
                {getPowerString(rad.dt50)} / {getPowerString(rad.dt60)} / {getPowerString(rad.dt70)}
            </TableCell>
            <TableCell align="right" style={{ padding: CELL_PADDING }}>
                <Typography variant="caption">
                    {rad.quantity}
                </Typography>
            </TableCell>
            <TableCell align="right" style={{ padding: CELL_PADDING }}><Typography variant="caption">{rad.priceRub.toLocaleString()}</Typography></TableCell>
            <TableCell align="right" style={{ padding: CELL_PADDING }}><Typography variant="caption">{totalCost.toLocaleString()}</Typography></TableCell>
            <TableCell align="right" style={{ padding: CELL_PADDING }}>
                <Typography variant="caption">
                    {discount}%
                </Typography>
            </TableCell>
            <TableCell align="right" style={{ padding: CELL_PADDING }}>
                <Typography variant="caption">
                    {totalCostDiscounted.toLocaleString()}
                </Typography>
            </TableCell>
        </TableRow>
    )


}

export default RadiatorListRow