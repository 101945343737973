import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'

import RadiatorList from '../RadiatorsList/RadiatorsList'

import { OfferRoom } from '../../model/offer.model'


type RoomProps = {
    roomId: string
    room: OfferRoom
}

const Room: React.FC<RoomProps> = ({ room, roomId }) => {

    if (room === undefined) return null

    return (
        <Box marginTop="2rem" marginBottom="1rem">
            <Grid container spacing={3} alignItems="baseline">
                <Grid item sm={6}>
                    <Typography variant="h6" color="primary">
                        {room.name}
                    </Typography>
                    <Typography variant="body1">
                        {room.description}
                    </Typography>
                </Grid>
                <Grid item sm={6}> 
                    <Typography variant="body1" color="primary">
                        Площадь: {room.square} м2
                    </Typography>
                    <Typography variant="body1" >
                        Расчетная мощность: {room.powerReq.toLocaleString()} Вт
                    </Typography>
                </Grid>
            </Grid>
            {room.radiators && Object.keys(room.radiators).length > 0 &&
                <Box marginTop="1rem">
                    <RadiatorList
                        radiators={room.radiators}
                    />
                </Box>
            }
            {!room.radiators &&
                <Box paddingY="1rem">
                    <Typography variant="body1">
                        Радиаторов и комплектующих нет
                    </Typography>
                </Box>
            }

        </Box>
    )
}

export default Room