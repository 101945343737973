import { Grid, Switch, Typography } from '@material-ui/core'
import React from 'react'

type ViewModeSwitcherProps = {
    roomsViewMode: boolean
    toggleViewMode: () => void
}

const ViewModeSwitcher: React.FC<ViewModeSwitcherProps> = ({ roomsViewMode, toggleViewMode }) => {

    return (
        <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Общий список</Grid>
                <Grid item>
                    <Switch
                        checked={roomsViewMode}
                        onChange={toggleViewMode}
                        name="viewMode"
                        color="primary"
                    />
                </Grid>
                <Grid item>По помещениям</Grid>
            </Grid>
        </Typography>
    )

}

export default ViewModeSwitcher