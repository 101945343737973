import { createMuiTheme } from '@material-ui/core/styles'


const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#1e88e5"
        },
        secondary: {
            main: "#ec407a",
        }
    },
    typography: {
        body1: {
            fontSize: "0.7rem"
        },
        caption: {
            fontSize: "0.6rem"
        }
    }
})

export default theme