import { Box } from '@material-ui/core'
import React from 'react'
import { OfferVersion } from '../../model/offer.model'
import Version from '../Version/Version'

type OfferVersionProps = {
    versions: { [versionId: string]: OfferVersion }
    roomsViewMode: boolean
}


const OfferVersions: React.FC<OfferVersionProps> = ({ versions, roomsViewMode }) => {

    return (
        <Box>
            {Object.entries(versions)
                .sort(([_, a], [__, b]) => a.createdAt > b.createdAt ? 1 : -1)
                .map(([versionId, version]) => {
                    return (
                        <Version
                            key={versionId}
                            version={version}
                            roomsViewMode={roomsViewMode}
                            versionsQnty={Object.keys(versions).length}
                        />
                    )
                })}
        </Box>
    )
}

export default OfferVersions