import React from 'react'
import { Box, Table, TableContainer } from '@material-ui/core'

import { OfferRadiator } from '../../model/offer.model'
import getRadiatorsTotal from '../../utils/getRadiatorsTotal'
import RadiatorListRow from './RadiatorListRow/RadiatorListRow'
import RadiatorListTableHeader from './RadiatorListTableHeader/RadiatorListTableHeader'
import RadiatorListTotal from './RadiatorsListTotal/RadiatorsListTotal'

type RadiatorsListProps = {
    radiators: { [radiatorId: string]: OfferRadiator }
}

const RadiatorList: React.FC<RadiatorsListProps> = ({ radiators }) => {

    const total = getRadiatorsTotal(radiators)
    const radiatorsArray = Object.entries(radiators)
        .map(([radiatorId, radiator]) => {
            return { ...radiator, id: radiatorId }
        })
        .sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)


    return (
        <Box>
            <TableContainer
                style={{ width: "full" }}
            >
                <Table>
                    <RadiatorListTableHeader />
                    {radiatorsArray
                        .filter(x => x.isAccessory === false)
                        .map(radiator => {
                            return (
                                <RadiatorListRow
                                    key={radiator.id}
                                    offerRadiator={radiator}
                                />
                            )
                        })
                    }
                    {radiatorsArray
                        .filter(x => x.isAccessory === true)
                        .map(radiator => {
                            return (
                                <RadiatorListRow
                                    key={radiator.id}
                                    offerRadiator={radiator}
                                />
                            )
                        })
                    }
                    <RadiatorListTotal
                        total={total}
                    />
                </Table>
            </TableContainer>
        </Box>
    )
}

export default RadiatorList