import { OfferVersion, OfferRadiator } from '../model/offer.model'
import removeDuplicateRadiators from './removeDuplicatesRadiators'


type ShrinkRadiators = (version: OfferVersion) => { [radiatorId: string]: OfferRadiator }

const shrinkRadiators: ShrinkRadiators = (version) => {

    let result: { [radiatorId: string]: OfferRadiator } = {}

    if (version.radiators) result = { ...version.radiators }

    if (version.rooms)
        Object.values(version.rooms)
            // eslint-disable-next-line array-callback-return
            .map(room => {
                if (room.radiators) result = { ...result, ...room.radiators }
            })
 
    const clearedResult = removeDuplicateRadiators(result)
    

    return clearedResult
}

export default shrinkRadiators